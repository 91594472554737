/* Header.css */
.navbar-arti {
  background-color: #0a0f17 !important;
  transition: background-color 0.4s ease;
  height: 130px;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;
  z-index: 9000;
  width: 100%;
}

.main-menu-right {
  display: flex;
  align-items: center;
  height: 130px;
  justify-content: space-around;
  margin-left: 4rem;
}

.navbar-arti:hover {
  background-color: white !important;
}

.navbar-arti:hover .menu-toggle-icon {
  color: black !important;
  border: 1px solid black !important;
}

.navbar-arti:hover .nav-link {
  color: black !important;
}

.navbar-arti:hover .nav-link:hover {
  color: #fc2b2d !important;
}

.navbar-arti:hover .nav-link.contact-us-btn {
  background-color: white !important;
  color: black !important;
  border-color: black !important;
}

.navbar-arti:hover .contact-us-btn:hover {
  background-color: #fc2b2d !important;
  border-color: #fc2b2d !important;
  color: white !important;
  text-decoration: none !important;
}

.language-selector-toggle {
  color: white !important;
}

.language-selector-menu {
  left: -25% !important;

  min-width: 6rem !important;
}

.navbar-arti:hover .language-selector-toggle {
  color: black !important;
}
.navbar-brand {
  color: white !important;
}

.sections-with-dropdown {
  display: flex;
  width: 100%;
  height: 130px;
}

.dropdown-activator {
  height: min-content;
  width: min-content;
  display: flex;
  align-items: center;
  z-index: 9001;
}
.dropdown-activator > .nav-link {
  margin: 0px 0px !important;
}

.dropdown-stay-active {
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  z-index: 9002;

  padding: 0px 25px 0px 25px;
}

.hidden-submenu-dropdown {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 220px;
  position: absolute;
  top: 90px;
  left: 0;
  background-color: transparent;
  opacity: 1;
  z-index: 9003;

  overflow: visible;
}

.submenu-dropdown {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 180px;
  position: absolute;
  top: 39px;
  left: 0;
  background-color: white;
  opacity: 1;
  transition: opacity 0.3s ease;
  border-top: 2px solid #9e9e9e;

  z-index: 9004;
  backdrop-filter: blur(100px);
}

.submenu-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: relative;
}

.last-submenu-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  position: relative;
}

.submenu-container::after {
  content: "";
  position: absolute;
  right: 0;
  top: 10%;
  bottom: 10%;
  width: 3px;
  background: #e1e1e1;
}
.submenu-img-top {
  width: auto;
  height: 8vh;
  mix-blend-mode: multiply;
  object-fit: contain;
}

.nav-link {
  color: white !important;
  margin: auto 25px auto 25px !important;
  width: 100%;
  font-size: 18px;
  position: relative;
  min-width: auto;
}

.nav-link:hover {
  color: #fc2b2d !important;
}

.nav-link.contact-us-btn:hover::after,
.nav-link.contact-us-btn.active::after {
  display: none;
}

.nav-link:hover::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 4px;
  color: #fc2b2d !important;
  background-color: #fc2b2d !important;
}

.contact-us-btn {
  background-color: black !important;
  border: 2px solid white !important;
  padding: 3px 6px;
  border-radius: 40px;
  display: inline-block;
  display: inline-block;
  text-align: center;
  transition: all 0.3s ease;
  white-space: nowrap;
  min-width: 120px;
}

.contact-us-btn:hover {
  background-color: #fc2b2d !important;
  border: 2px solid #fc2b2d !important;
  color: white;
  border-color: #fc2b2d !important;
  text-decoration: none;
}

.navbar-toggler {
  border-color: white !important;
}

.fa-search {
}
@media (pointer: coarse) {
  .navbar-arti:hover {
    background-color: black !important;
  }
  .navbar-arti:hover .menu-toggle-icon {
    color: white !important;
    border: 1px solid white !important;
  }
}

@media (max-width: 1399px) {
  .sections-with-dropdown {
    flex-direction: column;
    margin-bottom: 3rem !important;
  }
  .custom-collapsed-nav-bar {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 14rem 4rem 14rem 0px;
    background-color: white;
    border-top: 2px solid #9e9e9e;
  }
  .nav-link {
    margin-top: 1rem !important;
  }
  .submenu-dropdown {
  }
  .alternative-dropdown .drop-click .nav-link-no2 {
    margin-bottom: 1rem !important;
  }

  .dropdown-section-small-screen {
  }
  .alternative-dropdown > .drop-click {
    width: 215px !important;
    left: -1% !important;
  }
  .drop-click {
  }
  a.nav-link {
    left: -11% !important;
    width: 170px !important;
  }
  .dropdown-menu {
    padding: 1rem !important;
    left: -12% !important;
  }
  .nav-link-2 {
    display: flex !important;
    align-self: center !important;
    justify-content: start !important;
    font-size: 18px !important;
    padding: 8px 8px 8px 8px !important;
    min-width: auto;
  }
  .nav-link-2:hover {
    color: #fc2b2d !important;
    border-bottom: 4px solid #fc2b2d;
  }
}

/* SEARCH BAR */
.search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9005;
  animation: fadeInSearch 0.3s forwards;
}
@keyframes fadeInSearch {
  0% {
    top: -100%;
  }
  20% {
    top: -80%;
  }
  40% {
    top: -60%;
  }
  60% {
    top: -40%;
  }
  80% {
    top: -20%;
  }
  100% {
    top: 0;
  }
}
.search-box {
  display: flex;
  margin: 0;
  padding: 0;
  border-bottom: 6px solid #cacaca;
}
.search-button {
  background-color: transparent;
  display: flex;
  align-items: flex-end;
  border: none;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  color: #cacaca;
  font-size: small;
  font-weight: bolder;
  margin-top: 10%;
  margin-bottom: 1%;
}
.search-button:hover {
  background-color: #cacaca;
  color: #ffffff;
}

.input-container {
  position: relative;
  width: 100%;
}

.input-container input {
  font-weight: bolder;
  width: 100%;
  color: #cacaca;
  border: none;
  font-size: 5em;
  padding-right: 4rem;
}

.input-container .backspace-icon-container {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.search-box input::placeholder {
  color: #cacaca;
}

.search-box input:focus {
  outline: none;
}

.close-icon {
  position: absolute;
  font-size: xxx-large !important;
  top: 3rem;
  right: 3rem;
  cursor: pointer;
  animation: spin 0.6s ease-out;
  animation-iteration-count: 2;
  color: #505050;
}

.clear-icon {
  font-size: xxx-large !important;
  cursor: pointer;
  color: #fc2b2d;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .search-box {
    margin: 0rem 1rem 0rem 1rem;
  }
  .search-box input {
    font-size: 2em;
  }
  .close-icon {
    top: 2rem;
    right: 2rem;
  }
  .search-button {
    display: block;
    width: 20%;
    padding: 0;
    white-space: break-spaces;
    font-size: xx-small;
    font-weight: bolder;
    margin-top: 5%;
    margin-bottom: 1%;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .search-box {
    margin: 0rem 1rem 0rem 1rem;
  }
  .search-box input {
    font-size: 3.7em;
  }

  .search-button {
    padding: 0;
    font-size: xx-small;
    font-weight: bolder;
    margin-top: 11%;
    margin-bottom: 1%;
  }
}

/* LOADER */
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9100;
}
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

/* Apply the animations to the loader element */
.loader.fade-in {
  animation: fade-in 0.5s forwards;
}

.loader.fade-out {
  animation: fade-out 0.5s forwards;
}

.boxes-loader {
  --size: 96px;
  --duration: 800ms;
  height: calc(var(--size) * 2);
  width: calc(var(--size) * 3);
  position: relative;
  transform-style: preserve-3d;
  transform-origin: 50% 50%;
  margin-top: calc(var(--size) * 1.5 * -1);
  transform: rotateX(60deg) rotateZ(45deg) rotateY(0deg) translateZ(0px);
}

.boxes-loader .box {
  width: var(--size);
  height: var(--size);
  top: 0;
  left: 0;
  position: absolute;
  transform-style: preserve-3d;
}

.boxes-loader .box:nth-child(1) {
  transform: translate(100%, 0);
  -webkit-animation: box1 var(--duration) linear infinite;
  animation: box1 var(--duration) linear infinite;
}

.boxes-loader .box:nth-child(2) {
  transform: translate(0, 100%);
  -webkit-animation: box2 var(--duration) linear infinite;
  animation: box2 var(--duration) linear infinite;
}

.boxes-loader .box:nth-child(3) {
  transform: translate(100%, 100%);
  -webkit-animation: box3 var(--duration) linear infinite;
  animation: box3 var(--duration) linear infinite;
}

.boxes-loader .box:nth-child(4) {
  transform: translate(200%, 0);
  -webkit-animation: box4 var(--duration) linear infinite;
  animation: box4 var(--duration) linear infinite;
}

.boxes-loader .box > div {
  --background: #fd6b6c;
  --top: auto;
  --right: auto;
  --bottom: auto;
  --left: auto;
  --translateZ: calc(var(--size) / 2);
  --rotateY: 0deg;
  --rotateX: 0deg;
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--background);
  top: var(--top);
  right: var(--right);
  bottom: var(--bottom);
  left: var(--left);
  transform: rotateY(var(--rotateY)) rotateX(var(--rotateX))
    translateZ(var(--translateZ));
}

.boxes-loader .box > div:nth-child(1) {
  --top: 0;
  --left: 0;
}

.boxes-loader .box > div:nth-child(2) {
  --background: #fc2b2d;
  --right: 0;
  --rotateY: 90deg;
}

.boxes-loader .box > div:nth-child(3) {
  --background: #fd5557;
  --rotateX: -90deg;
}

.boxes-loader .box > div:nth-child(4) {
  --background: #dbe3f4;
  --top: 0;
  --left: 0;
  --translateZ: calc(var(--size) * 3 * -1);
}

@-webkit-keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(200%, 0);
  }
}

@keyframes box1 {
  0%,
  50% {
    transform: translate(100%, 0);
  }

  100% {
    transform: translate(200%, 0);
  }
}

@-webkit-keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@keyframes box2 {
  0% {
    transform: translate(0, 100%);
  }

  50% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(100%, 0);
  }
}

@-webkit-keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@keyframes box3 {
  0%,
  50% {
    transform: translate(100%, 100%);
  }

  100% {
    transform: translate(0, 100%);
  }
}

@-webkit-keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }

  50% {
    transform: translate(200%, 100%);
  }

  100% {
    transform: translate(100%, 100%);
  }
}

@keyframes box4 {
  0% {
    transform: translate(200%, 0);
  }

  50% {
    transform: translate(200%, 100%);
  }

  100% {
    transform: translate(100%, 100%);
  }
}

/* OVERLAY */
.overlay-submenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 2;
}
