main {
  min-height: 80%;
}

.card-img-top {
  width: 100%;
  mix-blend-mode: multiply;
  height: 14vw;
  object-fit: contain;
}

.card-title-center {
  text-align: center;
}

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.not-found h1 {
  font-size: 4rem;
  color: #444;
  margin-bottom: 1rem;
  text-align: center;
}

.not-found p {
  font-size: 1.5rem;
  color: #666;
  text-align: center;
}

.not-found img {
  background-color: transparent;
  border: none;
  padding: "20px";
  max-width: 13%;
  max-height: auto;
}

.spin-image {
  animation: spin360-3D 3s ease-out;
  transform-origin: 50% 50%;
  /* backface-visibility: hidden; */
}

@keyframes spin360-3D {
  0% {
    transform: perspective(600px) rotateY(0) scale(1);
  }
  17% {
    transform: perspective(600px) rotateY(-180deg) scale(1);
  }
  34% {
    transform: perspective(600px) rotateY(-360deg) scale(1);
  }
  51% {
    transform: perspective(600px) rotateY(-540deg) scale(1);
  }
  67% {
    transform: perspective(600px) rotateY(-720deg) scale(1);
  }
  84% {
    transform: perspective(600px) rotateY(-900deg) scale(1);
  }
  100% {
    transform: perspective(600px) rotateY(-1080deg) scale(1);
  }
}

.color-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    48,
    48,
    49,
    0
  ); /* Change this to your desired color and opacity */
  pointer-events: none; /* Prevents the overlay from interfering with the video player controls */
}

/* Newsletter */
.spinner-border-sm {
  height: 1.5em;
  width: 1.5em;
  border-width: 0.15em;
}

.checkmark-wrapper {
  position: relative;
  width: 4em;
  height: 4em;
  margin: 1em auto;
}

.checkmark-circle {
  animation: fill 0.4s ease-in-out forwards;
}

@keyframes fill {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

/* ABOUT COMPANY */
.section1-about-company {
  background-image: linear-gradient(90deg, #212165, #2c4197, #6e5496);
  background-clip: text;
  -webkit-background-clip: text;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
}

.section2-about-company {
  background-color: #242425;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 6rem;
  padding-top: 3rem;
  margin: 0;
  width: 100%;
}

.section3-about-company {
  background-color: #6f6f70;
  padding-bottom: 3rem;
  padding-top: 3rem;
  width: 100%;
}

.btn-offices {
  position: relative;
  height: 60px;
  padding: 0 30px;
  border: 2px solid #fff;
  background: #000;
  user-select: none;
  white-space: nowrap;
  transition: all 0.05s linear;
  font-family: inherit;
}

.btn-offices:before,
.btn-offices:after {
  content: "";
  position: absolute;
  background: #000;
  transition: all 0.2s linear;
}

.btn-offices:before {
  width: calc(100% + 6px);
  height: calc(100% - 16px);
  top: 8px;
  left: -3px;
}

.btn-offices:after {
  width: calc(100% - 16px);
  height: calc(100% + 6px);
  top: -3px;
  left: 8px;
}

.btn-offices:hover {
  cursor: crosshair;
}

.btn-offices:active {
  transform: scale(0.95);
}

.btn-offices:hover:before {
  height: calc(100% - 32px);
  top: 16px;
}

.btn-offices:hover:after {
  width: calc(100% - 32px);
  left: 16px;
}

.btn-offices span {
  color: white;
  font-size: 30px;
  letter-spacing: 2px;
  z-index: 3;
  position: relative;
  font-weight: 600;
}

/* CAREER */
.job-box {
}

.arrow-icon-career {
  animation: move-left-right 1s infinite alternate;
  opacity: 1;
  transition: opacity 0.2s;
}

.job-box:hover .arrow-icon-career {
  animation-play-state: running;
}

.job-box:not(:hover) .arrow-icon-career {
  animation-play-state: paused;
  opacity: 0.5;
}

@keyframes move-left-right {
  from {
    transform: translateX(-25px);
  }
  to {
    transform: translateX(25px);
  }
}

.link-job:hover {
  cursor: pointer;
}

.scrollable-col {
  max-height: 450px;
  overflow-y: auto;
}

.scrollable-col::-webkit-scrollbar {
  width: 10px;
}

.scrollable-col::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollable-col::-webkit-scrollbar-thumb {
  background-color: #212165;
  border-radius: 10px;
}

.scrollable-col::-webkit-scrollbar-thumb:hover {
  background-color: #101032;
}
@media (max-width: 767px) {
  .scrollable-col::-webkit-scrollbar-thumb {
    background-color: #212165;
    border-radius: 10px;
  }

  .scrollable-col::-webkit-scrollbar-thumb:hover {
    background-color: #101032;
  }
}

/* NEWS */
.link-new {
  overflow: hidden;
  position: relative;
}

.image-news-card {
  transition: transform 0.2s ease-in-out; /* Animation effect */
}

.link-new:hover {
  cursor: pointer;
}

.link-new:hover .image-news-card {
  transform: scale(1.05); /* Zoom the image by 10% */
}

.container-image-category-link {
}

/* NEWS PAGE */
/* .news-page .row {
  padding: 0rem !important;
  margin: 0rem !important;
  z-index: 0;
} */

/* PRODUCT PAGES */
.product-page .big-row {
  padding: 0rem !important;
  margin: 0rem !important;
}

.request-demo {
  padding: 0.2em 0.5em;
  width: 15em;
  height: 5em;
  background-color: #212121;
  border: 0.08em solid #fff;
  border-radius: 0.3em;
  font-size: 12px;
}

.fade-in {
  opacity: 0;
  transition: opacity 0.5s ease-in;
}

.fade-in.show {
  opacity: 1;
}

.request-demo span {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0.4em;
  width: 9.3em;
  height: 2.7em;
  background-color: #212121;
  border-radius: 0.2em;
  font-size: 1.5em;
  color: #fff;
  border: 0.08em solid #fff;
  box-shadow: 0 0.4em 0.1em 0.019em #fff;
}

.request-demo span:hover {
  transition: all 0.5s;
  transform: translate(0, 0.4em);
  box-shadow: 0 0 0 0 #fff;
}

.button span:not(hover) {
  transition: all 1s;
}

.icons-product-circle {
  cursor: pointer;
}

@media (max-width: 767px) {
  .icons-product-circle {
    width: 140px !important;
    height: 140px !important;
  }
  .selected-icons-product-circle {
    width: 175px !important;
    height: 175px !important;
  }
}

/* ALL NEWS PAGE */
.all-news-page .big-row {
  padding: 0rem 0rem !important;
  margin: 0rem 0rem !important;
}

/* SERVICE PAGE */
.schedule-meeting {
  --color1: #ed5034;
  --color2: #e13314;
  perspective: 1000px;
  padding: 1em 1em;
  background: linear-gradient(var(--color1), var(--color2));
  border: none;
  outline: none;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: #fff;
  text-shadow: 0 10px 10px #000;
  cursor: pointer;
  transform: rotateX(70deg) rotateZ(30deg);
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.schedule-meeting::before {
  content: "";
  width: 100%;
  height: 15px;
  background-color: var(--color2);
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotateX(90deg);
  transform-origin: bottom;
}

.schedule-meeting::after {
  content: "";
  width: 15px;
  height: 100%;
  background-color: var(--color1);
  position: absolute;
  top: 0;
  right: 0;
  transform: rotateY(-90deg);
  transform-origin: right;
}

.schedule-meeting:hover {
  transform: rotateX(30deg) rotateZ(0);
}

.feature-switch {
  padding: 2rem 2rem 0rem 0rem;
}

.switch {
  font-size: 1.2rem;
  position: relative;
  display: inline-block;
  width: 4em;
  height: 2em;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.feature-switch .slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background-color: #eee;
  transition: 0.4s;
  border-radius: 0.5em;
  box-shadow: 0 0.2em #dfd9d9;
}

.feature-switch .slider:before {
  position: absolute;
  content: "";
  height: 1.5em;
  width: 1.4em;
  border-radius: 0.3em;
  left: 0.3em;
  bottom: 0.7em;
  background-color: lightsalmon;
  transition: 0.4s;
  box-shadow: 0 0.4em #bcb4b4;
}

.feature-switch .slider:hover::before {
  box-shadow: 0 0.2em #bcb4b4;
  bottom: 0.5em;
}

.feature-switch input:checked + .slider:before {
  transform: translateX(2em);
  background: rgb(0, 255, 221);
}

.navigate-news {
  --green: #1bfd9c;
  font-size: 15px;
  padding: 0.7em 2.7em;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  border: 2px solid var(--green);
  background: linear-gradient(
    to right,
    rgba(27, 253, 156, 0.1) 1%,
    transparent 40%,
    transparent 60%,
    rgba(27, 253, 156, 0.1) 100%
  );
  color: var(--green);
  box-shadow: inset 0 0 10px rgba(27, 253, 156, 0.4),
    0 0 9px 3px rgba(27, 253, 156, 0.1);
}

.navigate-news:hover {
  color: #82ffc9;
  box-shadow: inset 0 0 10px rgba(27, 253, 156, 0.6),
    0 0 9px 3px rgba(27, 253, 156, 0.2);
}

.navigate-news:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform 0.4s ease-in-out;
  background: linear-gradient(
    to right,
    transparent 1%,
    rgba(27, 253, 156, 0.1) 40%,
    rgba(27, 253, 156, 0.1) 60%,
    transparent 100%
  );
}

.navigate-news:hover:before {
  transform: translateX(15em);
}

.navigate-contact-us-btn {
  --red: #fc2b2d;
  font-size: 15px;
  padding: 0.7em 2.7em;
  letter-spacing: 0.06em;
  position: relative;

  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  border: 2px solid var(--red);
  background: linear-gradient(
    to right,
    rgba(252, 43, 45, 0.1) 1%,
    transparent 40%,
    transparent 60%,
    rgba(252, 43, 45, 0.1) 100%
  );
  color: white;
  box-shadow: inset 0 0 10px rgba(252, 43, 45, 0.4),
    0 0 9px 3px rgba(252, 43, 45, 0.1);
}

.navigate-contact-us-btn:hover {
  color: #fc2b2d;
  box-shadow: inset 0 0 10px rgba(252, 43, 45, 0.6),
    0 0 9px 3px rgba(252, 43, 45, 0.2);
}

.navigate-contact-us-btn:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform 0.4s ease-in-out;
  background: linear-gradient(
    to right,
    transparent 1%,
    rgba(252, 43, 45, 0.1) 40%,
    rgba(252, 43, 45, 0.1) 60%,
    transparent 100%
  );
}

.navigate-contact-us-btn:hover:before {
  transform: translateX(15em);
}

@media (min-width: 1001px) and (max-width: 1200px) {
  .container-above-video .title {
    font-size: 25px;
    padding: 9rem 0rem 6rem 0rem;
  }
  .container-above-video .description {
    padding: 0rem 7rem 1rem 7rem !important;
  }
  .container-above-video .card-video {
    padding: 0.5rem !important;
    max-width: 300px !important;
  }
  .container-above-video .icon {
    font-size: 50px !important;
  }
  .container-above-video .motto {
    font-size: 20px;
  }
}

@media (min-width: 1201px) and (max-width: 1400px) {
  .container-above-video .title {
    font-size: 35px;
    padding: 9rem 0rem 6rem 0rem;
  }
  .container-above-video .description {
    padding: 0rem 7rem 1rem 7rem !important;
  }
  .container-above-video .card-video {
    padding: 0.5rem !important;
    max-width: 450px !important;
  }
  .container-above-video .icon {
    font-size: 60px !important;
  }
  .container-above-video .motto {
    font-size: 30px;
  }
}

@media (min-width: 1401px) and (max-width: 1700px) {
  .container-above-video .title {
    font-size: 40px;
    padding: 8rem 0rem 7rem 0rem;
  }
  .container-above-video .description {
    padding: 0rem 14rem 1rem 14rem !important;
  }
  .container-above-video .card-video {
    padding: 0.5rem !important;
    max-width: 450px !important;
  }
  .container-above-video .icon {
    font-size: 70px !important;
  }
  .container-above-video .motto {
    font-size: 30px;
  }
}

@media (min-width: 1701px) {
  .container-above-video .title {
    font-size: 45px;
    padding: 9rem 0rem 10rem 0rem;
  }
  .container-above-video .description {
    padding: 0rem 17rem 3rem 17rem !important;
  }
  .container-above-video .card-video {
    padding: 0.5rem !important;
    max-width: 450px !important;
  }
  .container-above-video .icon {
    font-size: 70px !important;
  }
  .container-above-video .motto {
    font-size: 35px;
  }
}

/* Loader Job Application */
.loader-job-application {
  width: 48px;
  height: 48px;
  margin: auto;
  position: relative;
}

.loader-job-application:before {
  content: "";
  width: 48px;
  height: 5px;
  background: #f0808050;
  position: absolute;
  top: 60px;
  left: 0;
  border-radius: 50%;
  animation: shadow324 0.5s linear infinite;
}

.loader-job-application:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #f08080;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4px;
  animation: jump7456 0.5s linear infinite;
}

@keyframes jump7456 {
  15% {
    border-bottom-right-radius: 3px;
  }

  25% {
    transform: translateY(9px) rotate(22.5deg);
  }

  50% {
    transform: translateY(18px) scale(1, 0.9) rotate(45deg);
    border-bottom-right-radius: 40px;
  }

  75% {
    transform: translateY(9px) rotate(67.5deg);
  }

  100% {
    transform: translateY(0) rotate(90deg);
  }
}

@keyframes shadow324 {
  0%,
  100% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1);
  }
}

.boxTest {
  width: 100%; /* Set the desired width */
  height: 100px; /* Set the desired height */
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.textTest {
  width: 100%;
  height: 100%;

  white-space: break-spaces;
  color: black;
}

.radio-input {
  display: flex;
  height: 90px;
  align-items: center;
}

.glass {
  z-index: 2;
  height: 110%;
  width: 40px;
  margin-right: 11px;
  padding: 8px;
  background-color: rgba(190, 189, 189, 0.5);
  border-radius: 35px;
  box-shadow: rgba(50, 50, 93, 0.2) 0px 25px 50px -10px,
    rgba(0, 0, 0, 0.25) 0px 10px 30px -15px,
    rgba(10, 37, 64, 0.26) 0px -2px 6px 0px inset;
  backdrop-filter: blur(8px);
}

.glass-inner {
  width: 100%;
  height: 100%;
  border-color: rgba(245, 245, 245, 0.45);
  border-width: 3.86px;
  border-style: solid;
  border-radius: 13px;
}

.selector {
  display: flex;
  flex-direction: column;
}

.choice {
  margin: 4.29px 0 4.29px 0;
  display: flex;
  align-items: center;
}

.contact-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
  margin-top: 20px;
}

.contact-description {
  font-size: 16px;
  color: #666;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 10px;
  align-items: start;
}

.form-group .input-group-text {
  display: flex;
  align-items: center;
}

.choice > div {
  position: relative;
  width: 17.5px;
  height: 17.5px;
  margin-right: 6.52px;
  z-index: 0;
}

.choice-circle {
  appearance: none;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  border-width: 3.86px;
  border-style: solid;
  border-color: rgba(245, 245, 245, 0.45);
  cursor: pointer;
  box-shadow: 0px 0px 20px -13px gray, 0px 0px 20px -14px gray inset;
}

.ball {
  z-index: 1;
  position: absolute;
  inset: 0px;
  transform: translateX(-40.77px);
  box-shadow: rgba(0, 0, 0, 0.17) 0px -10px 10px 0px inset,
    rgba(0, 0, 0, 0.15) 0px -15px 15px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -40px 20px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px,
    rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px,
    0px -1px 15px -8px rgba(0, 0, 0, 0.09);
  border-radius: 100%;
  transition: transform 800ms cubic-bezier(1, -0.4, 0, 1.4);
  background-color: rgb(232, 232, 232, 1);
}

.choice-circle:checked + .ball {
  transform: translateX(0px);
}

.choice-name {
  color: rgb(177, 176, 176);
  font-size: 20px;
  font-weight: 900;
  font-family: monospace;
  cursor: pointer;
}
.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

/* Triple Ven Diagram */
.venn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 700px;
  min-height: 700px;
  overflow: hidden;
}

.column-newsletter-text {
  padding-top: 7rem !important;
  padding-bottom: 3rem !important;
  padding-right: 0rem !important;
  padding-left: 10% !important;
}

@media only screen and (max-width: 768px) {
  .venn-container {
    transform: scale(0.6);
  }
}
@media (min-width: 769px) and (max-width: 1024px) {
  .product-text-section {
    width: fit-content !important;
    height: auto !important;
  }
  .big-post-title {
    font-size: 26px !important;
  }
  .big-post-small-description {
    font-size: 18px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1400px) {
}
@media (min-width: 951px) and (max-width: 1130px) {
  .venn-container {
    min-width: 600px;
    min-height: 600px;
    transform: scale(0.9);
  }
  .generic-row-main {
    margin: 0 5% 0 5% !important;
  }
  .column-newsletter-text {
    padding-left: 5% !important;
  }
  .column-newsletter-text h1 {
    font-size: 60px;
  }
}
@media (min-width: 1131px) and (max-width: 1350px) {
  .venn-container {
    min-width: 650px;
    min-height: 650px;
  }
  .generic-row-main {
    margin: 0 8% 0 8% !important;
  }
  .column-newsletter-text {
    padding-left: 8% !important;
  }
  .column-newsletter-text h1 {
    font-size: 80px;
  }
}
@media (min-width: 769px) and (max-width: 950px) {
  .venn-container {
    min-width: 550px;
    min-height: 550px;
    transform: scale(0.8);
  }
  .generic-row-main {
    margin: 0 3% 0 3% !important;
  }
  .column-newsletter-text {
    padding-left: 3% !important;
  }
  .column-newsletter-text h1 {
    font-size: 40px;
  }
}
@media (max-width: 769px) {
  .big-post-title {
    font-size: 26px !important;
  }
  .big-post-small-description {
    font-size: 18px !important;
  }
  .generic-row-main {
    margin: 0 1% 0 1% !important;
  }
  .product-text-section {
    padding-right: 2% !important;
  }
  .column-newsletter-text {
    padding-left: 5% !important;
    padding-right: 5% !important;
  }
  .column-newsletter-text h1 {
    font-size: 60px;
  }
  .products-section {
    text-align: start !important;
  }
  .column-latest-news-small-posts {
    padding: 0rem 0rem 0rem 0rem !important;
  }
  .row-clickable {
    margin: 0rem 1rem 1rem 1rem !important;
  }
  .services-feature-key-row-switch {
    margin: 0rem 0rem 0rem 0rem !important;
    padding: 0rem 0rem 0rem 0rem !important;
    width: 100%;
  }
}

@media (min-width: 1351px) {
  .generic-row-main {
    margin: 0 10% 0 10% !important;
  }
}

.service-feature-select {
  word-break: break-word !important;
  white-space: normal !important;
}

.circle {
  position: absolute;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.circle:hover {
  transform: scale(1.4);
}

.circle1 {
  background-color: rgba(255, 255, 255, 0.7);
  width: 250px;
  height: 250px;
  right: 19%;
  bottom: 20%;
}

.circle2 {
  background-color: rgba(240, 240, 240, 0.6);
  width: 250px;
  height: 250px;
  left: 19%;
  bottom: 20%;
}

.circle3 {
  background-color: rgba(225, 225, 225, 0.6);
  width: 250px;
  height: 250px;
  bottom: 45%;
  left: 46%;
  margin-left: -100px;
}

.clicked {
  transform: translate(0%, 0%) scale(2);
  animation: circleCombine 0.5s ease forwards;
}

@keyframes circleCombine {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.clicked.circle1 {
  z-index: 3;
}

.clicked.circle2 {
  z-index: 2;
}

.clicked.circle3 {
  z-index: 1;
}

.combined {
  background-color: #afafaf8e;
  width: 400px;
  height: 400px;
  left: 150px;
  bottom: 20%;
  z-index: 4;
  opacity: 0;
  transition: all 0.5s ease;
}

.clicked ~ .combined {
  opacity: 1;
}
h4 {
  color: white;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

h4::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
  transform: scaleX(0);
  transition: transform 0.2s ease-out;
}

h4:hover::before {
  transform: scaleX(1);
}

/* Contact Us */
.form-control:focus {
  background-color: #fff !important;
  border: 1.5px solid #fc2b2d !important;
  box-shadow: inset 0px 0px 0px 1px #fc2b2d !important;
}

/* Banner */
.banner-main {
  background-color: #fc2b2d !important;
  height: 32px;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  z-index: 9000;
  width: 100%;
}

.banner-navigate:hover {
  cursor: pointer;
}

.arrow-key-container {
  display: inline-block;
  text-align: center;
  transform: translate3d(0, 0, 0);
}
.arrow-key {
  align-items: center;
  background: #ccc;
  border-radius: 6px;
  box-shadow: -1px 1px 0 #999, -2px 2px 0 #999, -3px 3px 0 #999, -4px 4px 0 #999;
  color: #fff;
  display: inline-flex;
  font-size: 30px;
  font-weight: bold;
  height: 75px;
  justify-content: center;
  margin: 5px;
  transform: translate3d(0, 0, 0);
  transition: box-shadow 0.1s linear, transform 0.1s linear;
  width: 75px;
  &:active,
  &.press {
    box-shadow: 0px 0px 0 #999, 0px 0px 0 #999, 0px 0px 0 #999, -1px 1px 0 #999;
    transform: translate3d(-3px, 3px, 0);
  }
  &.left:before {
    content: "\2190";
  }
  &.up:before {
    content: "\2191";
  }
  &.down:before {
    content: "\2193";
  }
  &.right:before {
    content: "\2192";
  }
  &.esc:before {
    content: "\ esc";
  }
  &.shift:before {
    content: "\ ⇧";
  }
  &.space:before {
    content: "\ ⎵";
  }
  &.control:before {
    content: "\ Ctrl";
  }
  &.shift {
    width: 200px !important;
  }
  &.space {
    width: 400px !important;
  }
  &.control {
    width: 100px !important;
  }
}

.footer-item {
  font-size: 18px;
}

@media (min-width: 1700px) {
  .no-careers-available {
    height: 400px !important;
  }
  .no-careers-available-photo {
    max-width: 420px !important;
    height: 330px !important;
  }
}
.product-img-showcase:hover {
  transform: scale(1.2);
  transition: transform 0.7s;
  cursor: pointer;
}
.row-products-homepage {
  margin: 0rem 1rem 0rem 1rem !important;
}
