/* HomeScreen.css */

.home-screen {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
}

.results-section {
  background-color: #0a0f17;
  height: auto;
}

.results-section h1,
h5,
p {
  color: #ffffff;
}

.vennContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
  font-family: sans-serif;
}

.services-section {
  height: auto;
  background-color: #e9e9e9;
  padding: 4rem 0rem 9rem 0rem;
  overflow: hidden;
}

.products-section {
  height: auto;
  background-color: #0a0f17;
  padding: 7.5rem 0rem 2rem 0rem;
  text-align: end;
  overflow: hidden;
}

.partners-section {
  background-color: #f8f8f8;
  width: 100%;
  height: fit-content;
  padding: 4rem 0rem 4rem 0rem;
  text-align: start;
}

.scrollable-container {
  display: flex;
  flex-wrap: nowrap;
  height: fit-content;
  padding: 1rem;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #fc2b2d #fc2b2d;
}

.scrollable-container::-webkit-scrollbar {
  width: 12px;
  height: 10px;
  background-color: #f8f8f8;
}

.scrollable-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
  background-color: #f8f8f8;
  border-radius: 10px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: -webkit-linear-gradient(
    0deg,
    hsla(54, 93%, 71%, 1) 12%,
    hsla(15, 93%, 71%, 1) 39%,
    hsla(359, 97%, 58%, 1) 93%
  );
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background: #ff6c6f;
}

.scrollable-container::-webkit-scrollbar-corner {
  background-color: transparent;
}

.newsletter-sing-up-section {
  background-color: #e9e9e9;
  height: auto;
  overflow: hidden;
  padding: 2rem;
}

/* Bubbles */
.myBubbleUI {
  width: 100%;
  height: 100%;
  border-radius: 50px;
}

.bubbleComponent {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: burlywood;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bubbleComponent:hover {
  cursor: pointer;
}

.news-section {
  background-color: #000000;
  width: 100%;
  height: auto;
  padding: 4rem 0rem 4rem 0rem;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .news-section {
    padding: 4rem 1rem 4rem 1rem;
  }
  .scrollable-container {
    margin-left: 0rem;
    margin-right: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .partners-section {
    padding: 4rem 2rem 4rem 2rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .product-text-section {
    padding-right: 6% !important;
  }
}

@media (min-width: 1025px) and (max-width: 1200px) {
}

.row-clickable:hover {
  cursor: pointer;
}
